
import './App.css';
import {Box, Typography }from '@mui/material';


function App() {
  return (
    <div className="App">
      <Box sx={{ width: '100%', height: '100vh'}} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h1" gutterBottom>
        marlenknüsel woo
      </Typography>
      </Box>
    </div>
  );
}

export default App;
